import { consoleI18n } from '@ax/console-i18n';
import type { UnknownRecord } from '@ax/object-utils';
import type { ErrorObject, LimitParams } from 'ajv';
import type {
  VfjsUiSchema,
  JSONSchemaType,
} from 'vue-form-json-schema/dist/vue-form-json-schema.esm';

function buildSelect(property: string, obj: JSONSchemaType): VfjsUiSchema {
  return {
    component: 'ax-select',
    model: property,
    fieldOptions: {
      on: ['change'],
      props: {
        label: obj.title,
      },
    },
  };
}

function buildTextField(property: string, obj: JSONSchemaType): VfjsUiSchema {
  return {
    component: 'ax-text-field',
    model: property,
    fieldOptions: {
      on: ['input'],
      props: {
        label: obj.title,
        tooltip: obj.description,
        isSecret: obj.secret,
      },
    },
  };
}

function buildCombobox(property: string, obj: JSONSchemaType): VfjsUiSchema {
  return {
    component: 'ax-combobox',
    model: property,
    fieldOptions: {
      on: ['input'],
      props: {
        label: obj.title,
      },
    },
  };
}

export function generateUiSchema(
  schema: JSONSchemaType,
  root = '',
): VfjsUiSchema[] {
  const uiSchema: VfjsUiSchema[] = [];

  if (!schema || !schema.properties) {
    return uiSchema;
  }

  Object.keys(schema.properties).forEach((property) => {
    const fullySpecifiedProp = root === '' ? property : `${root}.${property}`;
    const obj = schema.properties![property];

    if (obj.$ref === '#/$defs/secret') {
      obj.$ref = undefined;
      obj.secret = true;
      obj.type = 'string';
    }

    const type = obj.type ? obj.type.toLowerCase() : null;

    if (!type) {
      // Can't determine the type, skip this entry.
    } else if (type === 'string') {
      if ('enum' in obj) {
        uiSchema.push(buildSelect(fullySpecifiedProp, obj));
      } else {
        uiSchema.push(buildTextField(fullySpecifiedProp, obj));
      }
    } else if (type === 'object') {
      uiSchema.push(...generateUiSchema(obj, fullySpecifiedProp));
    } else if (type === 'array') {
      uiSchema.push(buildCombobox(fullySpecifiedProp, obj));
    }
  });

  return uiSchema;
}

export interface SchemaStep {
  readonly schema: UnknownRecord;
  model: UnknownRecord;
  readonly name: string;
}

/**
 * Helper function to split schema into multiple sub-schema steps. Each top
 * level 'object' type in the schema will be considered a step. For now this
 * assumes that if you're calling this, the schema will always have  at least
 * one sub-object present.
 *
 * @param schema The schema to split into sub-steps.
 * @return SchemaStep[] Array of steps.
 */
export function getSchemaSteps(schema: JSONSchemaType): SchemaStep[] {
  const steps: SchemaStep[] = [];

  if (!schema || !schema.properties) {
    return steps;
  }

  Object.keys(schema.properties).forEach((property) => {
    const obj = schema.properties![property];
    if (obj.type && obj.type.toLowerCase() === 'object') {
      steps.push({
        schema: obj,
        model: {},
        name: property,
      });
    }
  });

  return steps;
}

/**
 * Helper function that combines the models from multiple schema steps back
 * into the full schema format for submission.
 *
 * @param steps List of steps with populated models to combine.
 * @return Object Combined model ready for submission.
 */
export function getCombinedSchemaModel(steps: SchemaStep[]) {
  return Object.fromEntries(steps.map(({ name, model }) => [name, model]));
}

export function localizeErrors(errors: ErrorObject[]) {
  if (!(errors && errors.length)) {
    return;
  }
  errors.forEach((e) => {
    let out;
    switch (e.keyword) {
      case 'minLength':
        out = consoleI18n.t('general.validations.minLength', {
          minLength: (e.params as LimitParams).limit,
        });
        break;
      case 'required':
        out = consoleI18n.t('general.validations.required');
        break;
      default:
        break;
    }
    if (out) {
      e.message = out;
    }
  });
}
